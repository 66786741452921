import React from 'react';

const TwitchLogin = () => {
    const CLIENT_ID = "13dg3zv9601qss5c4iry0sfpgra8hr";
    const REDIRECT_URI = window.location.hostname === "localhost"
      ? "http://localhost:3000/auth/callback"
      : "https://laoloser.com/auth/callback";
    
      const AUTH_URL = `https://id.twitch.tv/oauth2/authorize?response_type=token&client_id=${CLIENT_ID}&redirect_uri=${encodeURIComponent(REDIRECT_URI)}&scope=user%3Aread%3Aemail`;


  return (
    <div>
      <h1>Login with Twitch</h1>
      <a href={AUTH_URL}>
        <button>Login with Twitch</button>
      </a>
    </div>
  );
};

export default TwitchLogin;
