import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const AuthCallback = () => {
  const navigate = useNavigate();

  useEffect(() => {
    console.log("🔄 Processing Twitch authentication callback...");

    // Extract access token from URL fragment
    const hashParams = new URLSearchParams(window.location.hash.substring(1));
    const accessToken = hashParams.get('access_token');

    if (!accessToken) {
      console.error("❌ No access token found! Redirecting to login...");
      setTimeout(() => navigate('/'), 3000);
      return;
    }

    console.log("✅ Access Token received:", accessToken);
    localStorage.setItem('accessToken', accessToken);

    // Fetch Twitch user details
    const fetchUserData = async () => {
      try {
        console.log("📡 Fetching Twitch user data...");
        const response = await axios.get('https://api.twitch.tv/helix/users', {
          headers: {
            'Client-ID': '13dg3zv9601qss5c4iry0sfpgra8hr',
            'Authorization': `Bearer ${accessToken}`,
            'Accept': 'application/json'
          },
        });

        const userData = response.data.data[0];
        const channelId = userData.id;

        console.log(`✅ User authenticated: ${userData.display_name} (Channel ID: ${channelId})`);

        // Store Channel ID in localStorage
        localStorage.setItem('channelid', channelId);
        localStorage.setItem('username', userData.display_name);

        // Redirect to Dashboard
        navigate(`/dashboard?channelid=${channelId}`);
      } catch (error) {
        console.error("❌ Error fetching user data:", error);
        console.error("❌ Redirecting to login in 3 seconds...");
        setTimeout(() => navigate('/'), 3000);
      }
    };

    fetchUserData();
  }, [navigate]);

  return <h1>Authenticating...</h1>;
};

export default AuthCallback;
