import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from './components/old/Home';
import "./App.css";
import ToS from './components/tos';
import LiveWidget from './components/LiveWidget';
import Dashboard from './components/Dashboard';
import TwitchLogin from './components/TwitchLogin';
import AuthCallback from './components/AuthCallback';
import Home2 from './components/Home2';

const App = () => {
  const [searchResults, setSearchResults] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    const storedUsername = localStorage.getItem('username');

    if (storedToken && storedUsername) {
      setIsLoggedIn(true);
      setUsername(storedUsername);
    }
  }, []);

  const handleSearch = async (searchParams) => {
    try {
      const query = new URLSearchParams(searchParams).toString();
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/songs?${query}`);
      const data = await response.json();
      setSearchResults(Array.isArray(data) ? data : []);
    } catch (error) {
      console.error('Error fetching songs:', error);
      setSearchResults([]);
    }
  };

  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Home2 />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/auth/callback" element={<AuthCallback />} />
          <Route path="/tos" element={<ToS />} />
          <Route path="/livewidget" element={<LiveWidget />} />
          <Route path="/login" element={<TwitchLogin />} />


        </Routes>
      </div>
    </Router>
  );
};

export default App;
