import React from 'react';
import merchButton from '../assets/merchbutton.png';
import gachaButton from '../assets/gachabutton.png';
import youtubeButton from '../assets/youtubebutton.png';
import './Home2.css';

const Home2 = () => {
    const merchClick = () => {
        window.open("https://luckyprime.us/", "_blank");
    };

    const gachaClick = () => {
        window.open("https://laoloser.github.io/Gacha-Sim/", "_blank");
    };

    const youtubeClick = () => {
        window.open("https://www.youtube.com/@laoloserr", "_blank");
    }

    return (
        <div>
            <div id="merchDiv" class="links" onClick={merchClick}>
                <img src={merchButton} alt="Merch Button" onClick={merchClick} style={{ cursor: 'pointer' }} />
            </div>
            <div id="gachaDiv" class="links" onClick={gachaClick}>
                <img src={gachaButton} alt="Gacha Button" onClick={gachaClick} style={{ cursor: 'pointer' }} />
            </div>
            <div id='youtubeDiv' class="links" onClick={youtubeClick}>
                <img src={youtubeButton} alt="Youtube Button" onClick={youtubeClick} style={{ cursor: 'pointer'}} />
            </div>
        </div>
    );
};

export default Home2;
