import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Dashboard = () => {
  const [queue, setQueue] = useState([]);
  const [requestsEnabled, setRequestsEnabled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [username, setUsername] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  
  useEffect(() => {
    let ws; // WebSocket variable

    // ✅ Extract token from URL if available
    const hashParams = new URLSearchParams(window.location.hash.substring(1));
    const accessToken = hashParams.get('access_token');

    if (accessToken) {
      console.log("✅ Token received:", accessToken);
      localStorage.setItem('accessToken', accessToken);
      window.history.replaceState(null, null, window.location.pathname); // Clean URL
    }

    // ✅ Retrieve stored token
    const storedToken = localStorage.getItem('accessToken');
    if (!storedToken) {
      console.warn("⚠️ No access token found, redirecting to login...");
      navigate('/');
      return;
    }

    // **🔥 Validate token immediately & set hourly interval**
    validateSession(storedToken);
    const validationInterval = setInterval(() => {
      console.log("🔄 Running hourly token validation...");
      validateSession(localStorage.getItem('accessToken'));
    }, 60 * 60 * 1000); // 1 hour

    // ✅ Retrieve stored channel ID if missing in URL
    let channelid = searchParams.get('channelid') || localStorage.getItem('channelid');

    if (!channelid || channelid === 'null') {
      console.error("❌ No channelid found! Redirecting to home...");
      navigate('/');
      return;
    }

    // ✅ Update URL if missing `channelid`
    if (!searchParams.get('channelid')) {
      setSearchParams({ channelid });
    }

    // ✅ Store channelid in localStorage for future sessions
    localStorage.setItem('channelid', channelid);

    // ✅ Retrieve and set username
    const storedUsername = localStorage.getItem('username');
    if (storedUsername) {
      setUsername(storedUsername);
    }

    // ✅ Fetch song queue & settings
    fetchQueue(channelid);
    fetchRequestStatus(channelid);

    // ✅ Set up WebSocket connection
    let reconnectTimeout;
    const wsProtocol = window.location.protocol === "https:" ? "wss" : "ws";
    const wsUrl = `${wsProtocol}://twitchdb-production.up.railway.app?channelid=${channelid}`; 

    const connectWebSocket = () => {
      if (ws && ws.readyState === WebSocket.OPEN) return; // Prevent duplicate connections

      ws = new WebSocket(wsUrl);

      ws.onopen = () => {
        console.log("✅ WebSocket Connected");
        clearTimeout(reconnectTimeout);
      };

      ws.onmessage = (event) => {
        try {
          const message = JSON.parse(event.data);
          console.log("📩 WebSocket Message Received:", message);

          const currentChannelId = localStorage.getItem('channelid');
          if (message.channelid !== currentChannelId) return; // 🔹 Ignore messages for other channels

          console.log("🔄 Refreshing queue due to WebSocket update...");
          fetchQueue(currentChannelId);
          fetchRequestStatus(currentChannelId);
        } catch (error) {
          console.error("❌ Error processing WebSocket message:", error);
        }
      };

      ws.onerror = (error) => {
        console.error("❌ WebSocket Error:", error);
      };

      ws.onclose = () => {
        console.warn("⚠️ WebSocket Disconnected. Reconnecting in 5s...");
        reconnectTimeout = setTimeout(connectWebSocket, 5000);
      };
    };

    connectWebSocket();

    return () => {
      if (ws) ws.close(); // Close WebSocket on unmount
      clearTimeout(reconnectTimeout); // Stop reconnection attempts
      clearInterval(validationInterval); // ✅ Clear validation interval to prevent memory leaks
    };
}, []);

  

  // ✅ Validate Twitch OAuth Token
  const validateSession = async (token) => {
    try {
      const response = await axios.get('https://id.twitch.tv/oauth2/validate', {
        headers: { Authorization: `Bearer ${token}` }
      });
      console.log("✅ Token is still valid:", response.data);
    } catch (error) {
      console.error("❌ Token invalid or expired. Logging out...");
      localStorage.removeItem('accessToken');
      localStorage.removeItem('channelid');
      localStorage.removeItem('username');
      navigate('/');
    }
  };

  // ✅ Fetch song queue (Only for initial load)
  const fetchQueue = async (id) => {
    try {
      const response = await axios.get(`https://twitchdb-production.up.railway.app/api/requests?channelid=${id}`);
      setQueue(response.data);
    } catch (error) {
      console.error('❌ Error fetching queue:', error);
      toast.error('Error fetching song requests.');
    }
  };

  // ✅ Fetch request status (Open/Closed)
  const fetchRequestStatus = async (id) => {
    try {
      const response = await axios.get(`https://twitchdb-production.up.railway.app/api/settings?channelid=${id}`);
      setRequestsEnabled(response.data.enabled);
    } catch (error) {
      console.error('❌ Error fetching request status:', error);
      if (error.response && error.response.status === 404) {
        console.warn("⚠️ No settings found, defaulting to CLOSED.");
        setRequestsEnabled(false);
      } else {
        toast.error('Error fetching request status.');
      }
    } finally {
      setLoading(false);
    }
  };

  // ✅ Delete a single song request
  const handleDelete = async (id) => {
    try {
      const channelid = localStorage.getItem('channelid'); // Retrieve channelid
      await axios.delete(`https://twitchdb-production.up.railway.app/api/requests/${id}?channelid=${channelid}`);
      toast.success('Song deleted from the queue.');
      setQueue((prevQueue) => prevQueue.filter((request) => request.id !== id));
    } catch (error) {
      console.error('❌ Error deleting request:', error);
      toast.error('Failed to delete the song.');
    }
 };
 

  // ✅ Delete all song requests
  const handleDeleteAll = async () => {
    try {
      await axios.delete(`https://twitchdb-production.up.railway.app/api/requests?channelid=${searchParams.get('channelid')}`);
      toast.success('All songs deleted from the queue.');
      setQueue([]);
    } catch (error) {
      console.error('❌ Error deleting all requests:', error);
      toast.error('Failed to clear the queue.');
    }
  };

  if (loading) return <h1>Loading...</h1>;

  return (
    <div className="dashboard">
      <h1>Dashboard for {username || `Channel ${searchParams.get('channelid')}`}</h1>

      <h2>
        Song Requests: 
        <span style={{ color: requestsEnabled ? 'green' : 'red', fontWeight: 'bold' }}>
          {requestsEnabled ? ' OPEN' : ' CLOSED'}
        </span>
      </h2>

      <div className="controls">
        <button className="delete-all-button" onClick={handleDeleteAll} style={{ background: 'darkred', color: 'white' }}>
          🗑 Clear Queue
        </button>
      </div>

      <ul className="queue-list">
        {queue.length > 0 ? (
          queue.map((request) => (
            <li key={request.id} className="queue-item">
              <span>{request.song} - Requested by {request.username}</span>
              <button className="delete-button" onClick={() => handleDelete(request.id)}>Delete</button>
            </li>
          ))
        ) : (
          <p>No song requests yet.</p>
        )}
      </ul>

      <ToastContainer position="top-center" autoClose={2000} hideProgressBar={false} newestOnTop={false} closeOnClick pauseOnFocusLoss draggable pauseOnHover theme="dark" />
    </div>
  );
};

export default Dashboard;
